import React from 'react';
import Banners from '../../layouts/home/banner';
import Books from '../../layouts/home/books';

function AllBooks() {
  return (
    <>
      {/* <Banners /> */}
      <Books />
    </>
  );
}

export default AllBooks;
